import { Button } from '@tofu/shared/ui/atoms/button'
import { THeaderDesktopShopButton } from './header-desktop-shop-button.types'

export const HeaderDesktopShopButton: THeaderDesktopShopButton = (props) => (
  <Button
    as='span'
    p={2}
    pl={0}
    variant='link'
    fontSize='lg'
    textDecoration='none'
    fontWeight='light'
    _hover={{
      textDecoration: 'underline'
    }}
    {...props}
  />
)
